<template>
    <BT-Blade-Item
        bladeName="product"
        :bladesData="bladesData"
        navigation="products"
        :onPullSuccessAsync="pullProduct"
        title="Product"
        :otherUsedHeight="48">
        <template v-slot="{ item, data, proxyID, save }">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" class="d-flex flex-column justify-center align-center">
                        <BT-Image-Edit
                            :canEdit="proxyID == null"
                            :height="120"
                            :id="item.id"
                            justify-center align-center
                            navigation="product-image"
                            :src="productLogoURL(item.id) + '?' + cacheData"
                            @uploaded="cacheData = new Date().getTime()"
                            :width="120" />

                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.productName"
                            label="Product Name"
                            :isEditing="data.isEditing || data.isNew" />
                            
                        <BT-Field-String
                            v-model="item.abbreviation"
                            label="Abbreviation"
                            :isEditing="data.isEditing || data.isNew" />

                        <BT-Field-Number
                            v-model.number="item.sortNumber"
                            label="Sort Number"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                </v-row>
                <v-divider class="my-1" />
                <v-row v-if="!data.isNew">
                    <v-col cols="1">
                        <v-list width="56">
                            <BT-Menu
                                @close="save"
                                icon="mdi-human-male-height"
                                :isTrue="item => item.height || item.width || item.length || item.weight || item.volume"
                                :item="item"
                                navigation="products"
                                :proxyID="proxyID"
                                right
                                title="Dimensions">
                                <template v-slot="{ data, item }">
                                    <BT-Field-Number
                                        v-model.number="item.length"
                                        label="Length (mm)"
                                        :isEditing="data.isEditing" />
                                    
                                    <BT-Field-Number
                                        v-model.number="item.width"
                                        label="Width (mm)"
                                        :isEditing="data.isEditing" />
                                    
                                    <BT-Field-Number
                                        v-model.number="item.height"
                                        label="Height (mm)"
                                        :isEditing="data.isEditing" />
                                    
                                    <BT-Field-Number
                                        v-model.number="item.volume"
                                        label="Volume (Ltr)"
                                        :isEditing="data.isEditing" />

                                    <BT-Field-Number
                                        v-model.number="item.weight"
                                        label="Weight (Kg)"
                                        :isEditing="data.isEditing" />
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                v-if="proxyID == null"
                                icon="mdi-book-open-variant"
                                hideHeader
                                :itemValue="item.id"
                                navigation="product-ordering"
                                right
                                saveOnClose
                                title="Public">
                                <template v-slot="{ data, item }">

                                    <BT-Field-Switch
                                        v-model="item.isPublicProduct"
                                        label="Public"
                                        :isEditing="data.isEditing" />

                                    <BT-Field-Text-Area
                                        v-model="item.publicDescription"
                                        label="Public Description"
                                        :isEditing="data.isEditing" />

                                </template>
                            </BT-Menu>

                            <BT-Menu
                                v-if="proxyID == null"
                                @close="save"
                                icon="mdi-cookie"
                                hideHeader
                                :isTrue="item => item.isPerishable"
                                :item="item"
                                navigation="products"
                                right
                                title="Perishable">
                                <template v-slot="{ data, item }">

                                    <BT-Field-Switch
                                        v-model="item.isPerishable"
                                        label="Perishable"
                                        :isEditing="data.isEditing" />

                                    <BT-Field-Number
                                        v-model.number="item.expiryDays"
                                        label="Days Till Expiry"
                                        :isEditing="data.isEditing" />

                                </template>
                            </BT-Menu>

                            <BT-Menu
                                v-if="proxyID == null"
                                @close="save"
                                hideHeader
                                icon="mdi-factory"
                                :isTrue="item => item.isManufactured"
                                :item="item"
                                navigation="products"
                                right
                                title="Manufacturing">
                                <template v-slot="{ data, item }">

                                    <BT-Field-Switch
                                        v-model="item.isManufactured"
                                        label="Manufacturing"
                                        :isEditing="data.isEditing" />

                                    <BT-Field-Number
                                        v-model.number="item.expiryDays"
                                        label="Days Till Expiry"
                                        :isEditing="data.isEditing || data.isNew" />

                                    <BT-Field-String
                                        v-model="item.batchcodeFormat"
                                        label="Batchcode Format"
                                        :isEditing="data.isEditing || data.isNew" />

                                    <v-list-item>
                                        <v-list-item-content>
                                            <div>Sample: {{ $BlitzIt.auth.createTZ(item.batchcodeFormat) }}</div>
                                            <a href="https://moment.github.io/luxon/#/formatting?id=table-of-tokens" target="_blank" class="primary--text">What letters do I use to format?</a>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                v-if="proxyID == null"
                                @close="save"
                                hideHeader
                                icon="mdi-arrow-u-left-bottom"
                                :isTrue="item => item.isReturnable"
                                :item="item"
                                navigation="products"
                                right
                                title="Returnable">
                                <template v-slot="{ data, item }">

                                    <BT-Field-Switch
                                        v-model="item.isReturnable"
                                        label="Returnable"
                                        :isEditing="data.isEditing" />

                                    <v-divider />

                                    <BT-Entity
                                        :findItem="list => { return list.find(x => x.lineItemName == 'Refund' && x.itemID == item.id) }"
                                        inline
                                        :itemValue="item.id"
                                        navigation="automatic-line-items"
                                        :onNew="() => { return { id: null, sortNumber: item.sortNumber + 0.1, rule: 'ProductExists', quantity: 1, optionGroupID: null, lineValueType: 'FixedValue', lineValue: 0, linesWithTags: null, taxType: null, lineItemName: 'Refund', isOn: true, isReplacement: false, isLastMinute: true, description: 'Refund - ' + item.productName, calcOrder: 0, boundary: null, itemID: item.id } }"
                                        :params="{ lineItemName: 'Refund', productID: item.id }">
                                        <template v-slot="{ item, data, save }">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        Refund
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-list-item-action-text>
                                                        <BT-Mappings-Dialog
                                                            v-if="item.id != null"
                                                            buttonClass="small"
                                                            isEditing
                                                            :itemID="item.id"
                                                            navigation="order-invoice-mappings"
                                                            small />
                                                        <v-btn 
                                                            v-if="item.id != null" 
                                                            icon
                                                            small 
                                                            :to="{ name: 'automatic-line-item', params: { id: item.id } }"
                                                            title="Navigate to line">
                                                            <v-icon small>mdi-open-in-new</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action-text>
                                                    <v-text-field
                                                        v-model.number="item.lineValue"
                                                        :disabled="!data.isEditing"
                                                        hide-details
                                                        label="Unit Price"
                                                        prefix="$"
                                                        @change="save"
                                                        type="number" />
                                                </v-list-item-action>
                                            </v-list-item>
                                        </template>
                                    </BT-Entity>

                                    <v-divider />

                                    <BT-Entity
                                        :findItem="list => list.find(x => x.lineItemName == 'Deposit' && x.itemID == item.id)"
                                        inline
                                        :itemValue="item.id"
                                        navigation="automatic-line-items"
                                        :onNew="() => { return { id: null, sortNumber: item.sortNumber + 0.1, rule: 'ProductExists', quantity: 1, optionGroupID: null, lineValueType: 'FixedValue', lineValue: 0, linesWithTags: null, taxType: null, lineItemName: 'Deposit', isOn: true, isReplacement: false, isLastMinute: true, description: 'Deposit - ' + item.productName, calcOrder: 0, boundary: null, itemID: item.id } }"
                                        :params="{ lineItemName: 'Deposit', productID: item.id }">
                                        <template v-slot="{ item, data, save }">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        Deposit
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-list-item-action-text>
                                                        <BT-Mappings-Dialog
                                                            v-if="item.id != null"
                                                            buttonClass="small"
                                                            isEditing
                                                            :itemID="item.id"
                                                            navigation="order-invoice-mappings"
                                                            small />
                                                        <v-btn 
                                                            v-if="item.id != null" 
                                                            icon 
                                                            small 
                                                            :to="{ name: 'automatic-line-item', params: { id: item.id } }"
                                                            title="Navigate to line">
                                                            <v-icon small>mdi-open-in-new</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action-text>
                                                    <v-text-field
                                                        v-model.number="item.lineValue"
                                                        :disabled="!data.isEditing"
                                                        hide-details
                                                        label="Unit Price"
                                                        prefix="$"
                                                        @change="save"
                                                        type="number" />
                                                </v-list-item-action>
                                            </v-list-item>
                                        </template>
                                    </BT-Entity>

                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                hideHeader
                                icon="mdi-cart"
                                :isTrue="item => item.isSold"
                                :item="item"
                                navigation="products"
                                :proxyID="proxyID"
                                right
                                title="Sellable">
                                <template v-slot="{ data, item }">

                                    <BT-Field-Switch
                                        v-model="item.isSold"
                                        label="Sellable"
                                        :isEditing="data.isEditing" />

                                    <BT-Entity
                                        v-if="proxyID == null"
                                        inline
                                        :itemValue="item.id"
                                        navigation="product-ordering"
                                        single>
                                        <template v-slot="{ data, item, save }">
                                            <v-list-item dense>
                                                <v-list-item-content>
                                                    <BT-Tax-Type
                                                        @change="save"
                                                        :disabled="!data.isEditing"
                                                        v-model="item.taxType" />
                                                </v-list-item-content>
                                            </v-list-item>

                                            <BT-Field-Select
                                                @change="save"
                                                v-model="item.placeholderProductID"
                                                itemText="productName"
                                                label="Billable Product"
                                                navigation="products"
                                                :isEditing="data.isEditing" />

                                            <BT-Field-Select
                                                @change="save"
                                                v-model="item.categoryID"
                                                itemText="categoryName"
                                                label="Category"
                                                navigation="product-categories"
                                                :isEditing="data.isEditing" />
                                                
                                        </template>
                                    </BT-Entity>

                                    <v-divider v-if="proxyID == null" class="my-2" />
                                    <v-subheader v-if="proxyID == null">Xero Mappings</v-subheader>

                                    <BT-Mappings
                                        v-if="proxyID == null"
                                        navigation="order-invoice-mappings"
                                        itemType="Product"
                                        externalParty="xero"
                                        isEditing
                                        :itemID="item.id" />
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                hideHeader
                                icon="mdi-store"
                                :isTrue="item => item.isAcquired"
                                :item="item"
                                navigation="products"
                                :proxyID="proxyID"
                                right
                                title="Is Acquired">
                                <template v-slot="{ data, item }">

                                    <BT-Field-Switch
                                        v-model="item.isAcquired"
                                        label="Is Acquired"
                                        :isEditing="data.isEditing" />

                                </template>
                            </BT-Menu>

                            <BT-Menu
                                v-if="proxyID == null"
                                icon="mdi-tape-measure"
                                :item="item"
                                navigation="stock-increments"
                                right
                                title="Units Per Measurement">
                                <template v-slot="{ data, item }">
                                    <BT-Increments
                                        :isEditing="data.isEditing"
                                        :productID="item.id" />
                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                hideHeader
                                icon="mdi-sync"
                                :isTrue="item => item.isInfinite"
                                :item="item"
                                navigation="products"
                                :proxyID="proxyID"
                                right
                                title="Infinite">
                                <template v-slot="{ data, item }">

                                    <BT-Field-Switch
                                        v-model="item.isInfinite"
                                        label="Infinite"
                                        :isEditing="data.isEditing" />

                                </template>
                            </BT-Menu>

                            <BT-Menu
                                @close="save"
                                hideHeader
                                icon="mdi-thermometer"
                                :isTrue="item => item.isTemperatureSensitive"
                                :item="item"
                                navigation="products"
                                :proxyID="proxyID"
                                right
                                title="Temperature Sensitive">
                                <template v-slot="{ data, item }">
                                    <BT-Field-Switch
                                        v-model="item.isTemperatureSensitive"
                                        label="Temperature Sensitive"
                                        :isEditing="data.isEditing" />
                                </template>
                            </BT-Menu>

                        </v-list>
                        
                    </v-col>
                    <v-col v-if="proxyID == null" cols="11">
                        <v-row class="mt-2" dense>
                            <BT-Sidebar-Mappings
                                inline
                                isEditing
                                :itemID="item.id"
                                label="Mappings"
                                navigation="order-invoice-mappings"
                                small />

                            <BT-Sidebar-List
                                inline
                                label="Groups"
                                linkGetURL="/get/Links?ProductID={id}"
                                linkNavigation="product-groups"
                                manyText="groupName"
                                manyProp="productOfferingGroupID"
                                manyToMany
                                manyNavigation="product-groups"
                                :searchProps="['groupName']"
                                :singleID="item.id"
                                singleProp="productID"
                                small />

                            <BT-Sidebar-Items
                                :dividers="false"
                                inline
                                label="Thresholds"
                                navigation="stock-thresholds"
                                :onPullSuccessAsync="list => pullThresholds(list, item)"
                                :params="{ includeDetails: true, productID: item.id }"
                                small>
                                <template v-slot:listItem="{ item, isEditing }">
                                    <v-card class="my-1">
                                        <v-card-title>
                                            <BT-Entity
                                                navigation="locations"
                                                :itemValue="item.locationID"
                                                itemText="locationName" />
                                            <v-spacer />
                                            <v-btn v-if="item.id != null" small icon :to="{ name: 'stock-threshold', params: { id: item.id } }">
                                                <v-icon small>mdi-open-in-new</v-icon>
                                            </v-btn>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row dense>
                                                <v-spacer />
                                                <v-edit-dialog>
                                                    <div class="text-center">Lower</div>
                                                    <div class="text-center">{{ item.lowerThreshold | toDisplayNumber }}</div>
                                                    <template v-slot:input>
                                                        <v-text-field
                                                            v-model.number="item.lowerThreshold"
                                                            :disabled="!isEditing"
                                                            type="number" />
                                                    </template>
                                                </v-edit-dialog>
                                                <v-spacer />
                                                <v-edit-dialog>
                                                    <div class="text-center">Upper</div>
                                                    <div class="text-center">{{ item.upperThreshold | toDisplayNumber }}</div>
                                                    <template v-slot:input>
                                                        <v-text-field
                                                            v-model.number="item.upperThreshold"
                                                            :disabled="!isEditing"
                                                            type="number" />
                                                    </template>
                                                </v-edit-dialog>
                                                <v-spacer />
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </BT-Sidebar-Items>

                            <BT-Sidebar-List
                                inline
                                :item="item"
                                label="Components"
                                :linkGetURL="null"
                                linkPostURL="/post"
                                linkRemoveURL="/delete"
                                linkRemoveByDelete
                                linkNavigation="product-tethers"
                                :linkParams="{ triggeringProductID: item.id, isManufactured: true }"
                                manyText="productName"
                                manyProp="tetheredProductID"
                                manyToMany
                                manyNavigation="products"
                                :onNewLink="(manyItem, singleID, singleItem) => { return { id: null, triggeringProductID: singleID, triggeringProduct: singleItem, tetheredProductID: manyItem.id, tetheredProduct: manyItem, isManufactured: true, factor: 1, quantity: -1, showMore: false } }"
                                :onPullSuccessAsync="pullComponents"
                                :searchProps="['productName']"
                                :singleID="item.id"
                                singleProp="triggeringProductID"
                                small
                                sortProp="productName">
                                <template v-slot:listItem="{ item, remove, patch, refreshTracking, add, isEditing }">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn
                                                v-if="item.isChanged && item.link.id != null"
                                                small
                                                :loading="item.loadingCount > 0"
                                                @click="patch(item)">
                                                <v-icon small left>mdi-content-save</v-icon> Save
                                            </v-btn>
                                            <v-btn 
                                                v-else-if="item.link.id != null"
                                                class="success--text"
                                                :loading="item.loadingCount > 0"
                                                small
                                                @click="remove(item)">
                                                Use
                                            </v-btn>
                                            <v-btn
                                                v-else
                                                :loading="item.loadingCount > 0"
                                                @click="add(item)"
                                                small>
                                                Use
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-action>
                                            <v-edit-dialog>
                                                <span class="mr-2">{{ 0 - item.link.quantity | toDisplayNumber }}  x</span>
                                                <template v-slot:input>
                                                    <BT-Number
                                                        v-model.number="item.link.quantity"
                                                        @change="refreshTracking(item)"
                                                        :disabled="!isEditing"
                                                        label="Units"
                                                        reverse />
                                                </template>
                                            </v-edit-dialog>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title :title="item.manyItem.productName">{{ item.manyItem.productName }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.manyItem.abbreviation }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn small icon @click="item.link.showMore = !item.link.showMore">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="item.link.showMore" dense>
                                        <v-list-item-icon>
                                            <v-btn small>For Every</v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-action>
                                            <v-edit-dialog>
                                                <span class="mr-2">{{ item.link.factor | toDisplayNumber }} x</span>
                                                <template v-slot:input>
                                                    <BT-Number
                                                        v-model.number="item.link.factor"
                                                        @change="refreshTracking(item)"
                                                        :disabled="!isEditing"
                                                        label="Units" />
                                                </template>
                                            </v-edit-dialog>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title :title="item.link.triggeringProduct.abbreviation">{{ item.link.triggeringProduct.productName }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.link.triggeringProduct.abbreviation }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </BT-Sidebar-List>

                            <BT-Sidebar-Items
                                :canSearch="false"
                                :canSearchLocal="false"
                                :dividers="false"
                                inline
                                label="Sources"
                                navigation="product-tethers"
                                :params="{ tetheredProductID: item.id, isAcquired: true }"
                                :showFilters="false"
                                small>
                                <template v-slot:toolbar-right="{ allItems }">
                                    <v-btn @click="addSource(item, allItems)" small icon>
                                        <v-icon small>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:listItem="{ item, allItems }">
                                    <v-card class="my-1">
                                        <v-card-title>
                                            <v-btn class="mr-1" small icon @click="removeSource(item, allItems)">
                                                <v-icon small class="error--text">mdi-close</v-icon>
                                            </v-btn>
                                            {{ item.triggeringProduct.productName }}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            <BT-Entity
                                                class="ml-5"
                                                itemText="companyName"
                                                :itemValue="item.triggeringProduct.companyAccountID"
                                                navigation="public-companies"
                                                single />
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <v-row dense>
                                                <v-spacer />
                                                <v-edit-dialog>
                                                    <div class="text-center" title="Minimum Order Quantity">MOQ</div>
                                                    <div class="text-center">{{ item.minimumOrderQuantity | toDisplayNumber }}</div>
                                                    <template v-slot:input>
                                                        <v-text-field
                                                            v-model.number="item.minimumOrderQuantity"
                                                            type="number" />
                                                    </template>
                                                </v-edit-dialog>
                                                <v-spacer />
                                                <v-edit-dialog>
                                                    <div class="text-center">Order Increments</div>
                                                    <div class="text-center">{{ item.orderInIncrements | toDisplayNumber }}</div>
                                                    <template v-slot:input>
                                                        <v-text-field
                                                            v-model.number="item.orderInIncrements"
                                                            type="number" />
                                                    </template>
                                                </v-edit-dialog>
                                                <v-spacer />
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </template>
                            </BT-Sidebar-Items>
                            <!-- <BT-Sidebar-List
                                :block="false"
                                buttonClass="mx-1"
                                :canEdit="false"
                                :canSearch="false"
                                :canSearchLocal="false"
                                label="Sources"
                                :linkGetURL="null"
                                linkPostURL="/post"
                                linkRemoveURL="/delete"
                                linkRemoveByDelete
                                linkNavigation="product-tethers"
                                :linkParams="{ tetheredProductID: item.id, isAcquired: true }"
                                manyText="productName"
                                manyProp="triggeringProductID"
                                manyToMany
                                :onPullSuccessAsync="pullExternalSources"
                                :searchProps="['productName']"
                                :showFilters="false"
                                :singleID="item.id"
                                singleProp="tetheredProductID"
                                sortProp="productName">
                                <template v-slot:toolbar-right="{ allItems }">
                                    <v-btn @click="addSource(item, allItems)" small icon>
                                        <v-icon small>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:listItem="{ item, remove, patch, refreshTracking, add, isEditing }">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-btn
                                                v-if="item.isChanged && item.link.id != null"
                                                small
                                                :loading="item.loadingCount > 0"
                                                @click="patch(item)">
                                                <v-icon small left>mdi-content-save</v-icon> Save
                                            </v-btn>
                                            <v-btn 
                                                v-else-if="item.link.id != null"
                                                class="success--text"
                                                :loading="item.loadingCount > 0"
                                                small
                                                @click="remove(item)">
                                                Source
                                            </v-btn>
                                            <v-btn
                                                v-else
                                                :loading="item.loadingCount > 0"
                                                @click="add(item)"
                                                small>
                                                Source
                                            </v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-action>
                                            <v-edit-dialog>
                                                <span class="mr-2">{{ 0 - item.link.quantity | toDisplayNumber }}  x</span>
                                                <template v-slot:input>
                                                    <BT-Number
                                                        v-model.number="item.link.quantity"
                                                        @change="refreshTracking(item)"
                                                        :disabled="!isEditing"
                                                        label="Units"
                                                        reverse />
                                                </template>
                                            </v-edit-dialog>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.manyItem.productName }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </BT-Sidebar-List> -->
                            
                            <v-spacer />
                            
                            <BT-Sidebar-External-Links
                                inline
                                :itemID="item.id"
                                label="Integrate"
                                navigation="products"
                                syncNavigation="product-syncing"
                                small />
                        </v-row>
                        <v-row dense>
                            <v-col v-for="(s, index) in stockLevels" :key="index">
                                <v-card width="auto" height="200">
                                    <v-card-title>{{ s.location.locationName }}</v-card-title>
                                    <v-card-text style="margin-top: 50px">
                                        <v-container class="d-flex justify-center">
                                            <v-progress-circular
                                                size="120"
                                                width="10"
                                                class="progress align-self-center"
                                                color="primary"
                                                :value="(s.upper != null ? ((s.onHand - s.lower) / (s.upper - s.lower)) : s.onHand) * 100">
                                                <div class="text-center">
                                                    <h4>In Stock</h4>
                                                    <div><strong>{{ s.onHand | toDisplayNumber }}</strong></div>
                                                    <div>{{ (s.upper != null ? ((s.onHand - s.lower) / (s.upper - s.lower)) : s.onHand) | toWholePercent }}</div>
                                                </div>
                                            </v-progress-circular>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <BT-Sidebar-Items
                                            customURL="/get/ByProduct"
                                            :dividers="false"
                                            inline
                                            label="Assignments"
                                            navigation="stock-consignments"
                                            :params="{ departureLocationID: s.location.id, stockProductID: item.id }"
                                            small>
                                            <template v-slot:listItem="listItemData">
                                                <v-list-item>
                                                    <v-list-item-action>
                                                        {{ listItemData.item.consignmentEntryItems.find(z => z.productID == item.id).quantity | toDisplayNumber }} units
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ listItemData.item.buyer.companyName }}</v-list-item-title>
                                                        <v-list-item-subtitle>#{{ listItemData.item.consignmentNumber }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-list-item-action-text>
                                                            <v-btn small icon :to="{ name: 'stock-consignment', params: { id: listItemData.item.id } }">
                                                                <v-icon small>mdi-open-in-new</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action-text>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </BT-Sidebar-Items>
                                        <v-spacer />
                                        <BT-Sidebar-Items
                                            customURL="/get/ByProduct"
                                            :dividers="false"
                                            inline
                                            label="Orders"
                                            navigation="stock-consignments"
                                            :params="{ departureLocationID: s.location.id, orderProductID: item.id }"
                                            small>
                                            <template v-slot:listItem="listItemData">
                                                <v-list-item>
                                                    <v-list-item-action>
                                                        {{ listItemData.item.consignmentOrderItems.find(z => z.productID == item.id).quantity | toDisplayNumber }} units
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ listItemData.item.buyer.companyName }}</v-list-item-title>
                                                        <v-list-item-subtitle>#{{ listItemData.item.consignmentNumber }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-list-item-action-text>
                                                            <v-btn small icon :to="{ name: 'stock-consignment', params: { id: listItemData.item.id } }">
                                                                <v-icon small>mdi-open-in-new</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action-text>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </BT-Sidebar-Items>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12">
                                <BT-Blade-Items
                                    actualHeight="325px"
                                    addBladeName="batch"
                                    archiveBladeName="batch-archives"
                                    :canRefresh="false"
                                    canSearchLocal
                                    flexColumn
                                    :headers="[
                                        { text: 'Batchcode', value: 'batchcode', csv: true, subtitle: 1, searchable: true },
                                        { text: 'DOM', value: 'dom', textFilter: 'toShortDate', csv: true, subtitle: 2, searchable: true, prefix: 'DOM: ' },
                                        { text: 'EXP', value: 'exp', textFilter: 'toShortDate', csv: true },
                                        { text: 'Quantity', value: 'quantity', textFilter: 'toDisplayNumber', breakdown: true, isIcon: true },
                                        { text: 'Status', value: 'status', display: true, subtitle: 3 }]"
                                    hideBackButton
                                    :hideFooter="false"
                                    hideHeader
                                    :itemsPerPage="6"
                                    navigation="batches"
                                    :params="{ productID: item.id }"
                                    showTable
                                    title="Recent Batches">
                                    <template v-slot:toolbar-right>
                                        <v-btn class="primary" small :to="{ name: 'batch-archives', query: { productID: item.id } }">View All</v-btn>
                                    </template>
                                    <template v-slot:status="{ item }">
                                        <span v-if="item.isPending">Pending</span>
                                        <span v-else-if="item.onHold">On Hold</span>
                                        <span v-else>Released</span>
                                    </template>
                                    <template v-slot:quantity="{ item }">
                                        <span v-if="item.isPending">({{ item.quantityGoal | toDisplayNumber }})</span>
                                        <span v-else>{{ item.quantity | toDisplayNumber }}</span>
                                    </template>
                                    <template v-slot:itemActions="{ item }">
                                        <BT-Btn
                                            bladeName="batch-tracking"
                                            :id="item.id"
                                            leftIcon="mdi-radar"
                                            small
                                            title="Batch tracking" />
                                    </template>
                                </BT-Blade-Items>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Product-Blade',
    components: {
        BTImageEdit: () => import('~components/BT-Image-Edit.vue'),
        BTIncrements: () => import('~components/BT-Increments.vue'),
        BTMappings: () => import('~components/BT-Mappings.vue'),
        BTMappingsDialog: () => import('~components/BT-Mappings-Dialog.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
        BTSidebarItems: () => import('~components/BT-Sidebar-Items.vue'),
        BTSidebarExternalLinks: () => import('~components/BT-Sidebar-External-Links.vue'),
        BTSidebarMappings: () => import('~components/BT-Sidebar-Mappings.vue'),
    },
    data: function() {
        return {
            cacheData: new Date().getTime(),
            stockLevels: [],
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        async addSource(prod, allSources) {
            try {
                var supp = await this.$selectItem({
                    itemText: 'seller.companyName',
                    label: 'Supplier',
                    navigation: 'suppliers'
                });

                if (supp != null) {
                    var policyRes = await this.$BlitzIt.api.getById('suppliers', supp.id, null, null, '/get/Policy');
                    var policy = policyRes.data.data;

                    console.log('policy');
                    console.log(policy);

                    var products = policyRes.data.data.orderItems.map(z => z.product);

                    var product = await this.$selectItemFrom({
                        items: products,
                        itemText: 'productName',
                        label: 'Product'
                    });

                    var tether = {
                        id: null,
                        rowVersion: null,
                        tetheredProductID: prod.id,
                        tetheredProduct: prod,
                        triggeringProductID: product.id,
                        triggeringProduct: product,
                        isAcquired: true,
                        isManufactured: false,
                        factor: 1,
                        quantity: 1,
                        minimumOrderQuantity: 0,
                        orderInIncrements: 0
                    };

                    // var res = await this.$BlitzIt.store.post('product-tethers', tether);

                    // tether.id = res.id;
                    // tether.rowVersion = res.rowVersion;

                    allSources.push(tether);
                }
            }
            catch (err) {
                console.log(this.extractErrorDescription(err));
            }
        },
        async removeSource(src, sources) {
            try {
                var ind = sources.findIndex(z => z === src);
                if (ind >= 0) {
                    await this.$BlitzIt.store.delete('product-tethers', src.id);
                    sources.splice(ind, 1);
                }
            }
            catch (err) {
                console.log(this.extractErrorDescription(err));
            }
        },
        async pullComponents(list) {
            if (this.isLengthyArray(list)) {
                list.forEach(l => {
                    l.link = Object.assign({}, { ...l.link, showMore: l.link.factor != 1 })
                });
            }

            return list;
            // var r = this.isLengthyArray(list) ? list : [];
            
            // console.log('components');
            // console.log(r);
            
            // return r.map(x => { return Object.assign({}, { ...x, showMore: x.factor != 1 })});
        },
        async pullThresholds(res, product) {
            var locations = await this.$BlitzIt.store.getAll('locations');

            if (this.isLengthyArray(locations)) {
                locations.forEach(l => {
                    if (!res.some(x => x.locationID == l.id)) {
                        //add new
                        res.push({
                            id: null,
                            locationID: l.id,
                            productID: product.id,
                            lowerThreshold: 0,
                            upperThreshold: 0,
                            minimumOrderAmount: 0,
                            orderIncrement: 0
                        });
                    }
                })
            }

            return res;
        },
        async pullProduct(res) {
            var thresholds = await this.$BlitzIt.store.getAll('stock-thresholds', { productID: res.id });
            var trackedItems = await this.$BlitzIt.store.getAll('stock-tracked-items', { productID: res.id });
            var locations = await this.$BlitzIt.store.getAll('locations');

            this.stockLevels = locations.map(l => {
                var stockLevel = {
                    location: l,
                    upper: null,
                    lower: 0,
                    onHand: 0
                };

                var tracked = trackedItems.find(x => x.locationID == l.id);
                if (tracked != null) {
                    stockLevel.onHand = tracked.inStock;
                }

                var threshold = thresholds.find(x => x.locationID == l.id);
                if (threshold != null) {
                    if (threshold.upperThreshold > 0) {
                        stockLevel.upper = threshold.upperThreshold; //== 0 ? threshold.onHand : threshold.upperThreshold;
                    }
                    stockLevel.lower = threshold.lowerThreshold;
                }
                
                return stockLevel;
            });
            
            return res;
        }
    }
}
</script>

<style scoped>

.progress {
    position: absolute;
    top: auto;
    bottom: auto;
}

</style>